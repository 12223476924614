// Generated by Avo VERSION 169.63.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "77zlQpk0r2rl6rUt3Qf7";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "IK4lfTOvPGWHmk0ieLNw",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "5PhajbVijwhXVKIJtGMT",
          "se": (new Date()).toISOString(),
          "so": "dOs03aBc9",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "IK4lfTOvPGWHmk0ieLNw",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "5PhajbVijwhXVKIJtGMT",
          "se": (new Date()).toISOString(),
          "so": "dOs03aBc9",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "5PhajbVijwhXVKIJtGMT",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export interface Refinements {
  refinementPath: string
  refinementType: RefinementTypeValueType
}

export const SingleSignOnService = {
  'EMAIL': 'Email',
  'GOOGLE': 'Google',
  'MICROSOFT': 'Microsoft',
} as const;
export type SingleSignOnServiceType = typeof SingleSignOnService;
export type SingleSignOnServiceValueType = SingleSignOnServiceType[keyof SingleSignOnServiceType];

export const ModeratedContentType = {
  'LANGUAGE_AND_DISCRIMINATION': 'language and discrimination',
  'VIOLENCE_AND_CRIME': 'violence and crime',
  'UPSETTING_DISTURBING_SENSITIVE': 'upsetting disturbing sensitive',
  'NUDITY_AND_SEX': 'nudity and sex',
  'PHYSICAL_SAFETY': 'physical safety',
  'TOXIC': 'toxic',
} as const;
export type ModeratedContentTypeType = typeof ModeratedContentType;
export type ModeratedContentTypeValueType = ModeratedContentTypeType[keyof ModeratedContentTypeType];

export const EventVersion = {
  '2_0_0': '2.0.0',
} as const;
export type EventVersionType = typeof EventVersion;
export type EventVersionValueType = EventVersionType[keyof EventVersionType];

export const ResourceFileType = {
  'SHARE_TO_GOOGLE_DRIVE': 'share to google drive',
  'PDF': 'pdf',
  'DOCX': 'docx',
  'PPTX': 'pptx',
  'ALL': 'all',
} as const;
export type ResourceFileTypeType = typeof ResourceFileType;
export type ResourceFileTypeValueType = ResourceFileTypeType[keyof ResourceFileTypeType];

export const ComponentType = {
  'HAMBURGER_MENU_BUTTON': 'hamburger_menu_button',
  'TEXT_INPUT': 'text_input',
  'REGENERATE_RESPONSE_BUTTON': 'regenerate_response_button',
  'SELECT_OAK_LESSON': 'select_oak_lesson',
  'TYPE_EDIT': 'type_edit',
  'LESSON_FINISH_CHECK': 'lesson_finish_check',
  'CONTINUE_BUTTON': 'continue_button',
  'CONTINUE_TEXT': 'continue_text',
  'GO_TO_SHARE_PAGE_BUTTON': 'go_to_share_page_button',
  'EXAMPLE_LESSON_BUTTON': 'example_lesson_button',
  'HOMEPAGE_PRIMARY_CREATE_A_LESSON_BUTTON': 'homepage_primary_create_a_lesson_button',
  'HOMEPAGE_SECONDARY_CREATE_A_LESSON_BUTTON': 'homepage_secondary_create_a_lesson_button',
  'FOOTER_MENU_LINK': 'footer_menu_link',
  'FILTER_LINK': 'filter_link',
  'KEYSTAGE_KEYPAD_BUTTON': 'keystage_keypad_button',
  'LESSON_CARD': 'lesson_card',
  'LESSON_DOWNLOAD_BUTTON': 'lesson_download_button',
  'PROGRAMME_CARD': 'programme_card',
  'SEARCH_BUTTON': 'search_button',
  'SEARCH_RESULT_ITEM': 'search_result_item',
  'SHARE_BUTTON': 'share_button',
  'SUBJECT_CARD': 'subject_card',
  'UNIT_CARD': 'unit_card',
  'SIGNUP_FORM': 'signup_form',
  'SKIP_BUTTON': 'skip_button',
  'YEAR_KEYPAD_BUTTON': 'year_keypad_button',
  'UNIT_DOWNLOAD_BUTTON': 'unit_download_button',
  'SEARCH_GET_STARTED_BUTTON': 'search_get_started_button',
  'AIMS_AND_PURPOSE': 'aims_and_purpose',
  'CURICULUM_COHERENCE': 'curiculum_coherence',
  'CURRICULUM_DELIVERY': 'curriculum_delivery',
  'CURRICULUM_VISUALISER_BUTTON': 'curriculum_visualiser_button',
  'DOWNLOAD_BUTTON': 'download_button',
  'DOWNLOAD_TAB': 'download_tab',
  'EXPLAINER_TAB': 'explainer_tab',
  'FOLLOWING_UNIT_DESC': 'following_unit_desc',
  'HOMEPAGE_BUTTON': 'homepage_button',
  'HOMEPAGE_TAB': 'homepage_tab',
  'LANDING_PAGE_BUTTON': 'landing_page_button',
  'LEARNING_TIER_BUTTON': 'learning_tier_button',
  'LESSONS_IN_UNIT': 'lessons_in_unit',
  'NATIONAL_CURRICULUM': 'national_curriculum',
  'OAK_CURRICULUM_PRINCIPLES': 'oak_curriculum_principles',
  'OAK_SUBJECT_PRINCIPLES': 'oak_subject_principles',
  'OUR_CURRICULUM_PARTNER': 'our_curriculum_partner',
  'PREVIOUS_UNIT_DESC': 'previous_unit_desc',
  'RECOMMENDATIONS_FROM_SUBJECT_SPECIFIC_REPORTS': 'recommendations_from_subject_specific_reports',
  'SEE_LESSONS_IN_UNIT_BUTTON': 'see_lessons_in_unit_button',
  'SUBJECT_CATEGORY_BUTTON': 'subject_category_button',
  'SUBJECT_SPECIFIC_NEEDS': 'subject_specific_needs',
  'UNIT_INFO_BUTTON': 'unit_info_button',
  'UNIT_SEQUENCE_TAB': 'unit_sequence_tab',
  'VIDEO': 'video',
  'WHY_THIS_WHY_NOW': 'why_this_why_now',
  'YEAR_GROUP_BUTTON': 'year_group_button',
  'ADD_ADDITIONAL_MATERIALS_BUTTON': 'add_additional_materials_button',
  'MODIFY_BUTTON': 'modify_button',
} as const;
export type ComponentTypeType = typeof ComponentType;
export type ComponentTypeValueType = ComponentTypeType[keyof ComponentTypeType];

export const AnalyticsUseCase = {
  'PUPIL': 'Pupil',
  'TEACHER': 'Teacher',
} as const;
export type AnalyticsUseCaseType = typeof AnalyticsUseCase;
export type AnalyticsUseCaseValueType = AnalyticsUseCaseType[keyof AnalyticsUseCaseType];

export const ResourceType = {
  'SLIDE_DECK': 'slide deck',
  'LESSON_PLAN': 'lesson plan',
  'STARTER_QUIZ': 'starter quiz',
  'EXIT_QUIZ': 'exit quiz',
  'WORKSHEET': 'worksheet',
  'ADDITIONAL_MATERIALS': 'additional materials',
  'CURRICULUM_PLAN': 'curriculum plan',
  'PREVIOUSLY_RELEASED_CURRICULUM_PLAN': 'previously released curriculum plan',
} as const;
export type ResourceTypeType = typeof ResourceType;
export type ResourceTypeValueType = ResourceTypeType[keyof ResourceTypeType];

export const Product = {
  'AI_LESSON_ASSISTANT': 'ai lesson assistant',
  'CURRICULUM_RESOURCES': 'curriculum resources',
  'CURRICULUM_VISUALISER': 'curriculum visualiser',
  'PUPIL_LESSON_ACTIVITIES': 'pupil lesson activities',
  'TEACHER_LESSON_RESOURCES': 'teacher lesson resources',
  'USER_ACCOUNT_MANAGEMENT': 'user account management',
} as const;
export type ProductType = typeof Product;
export type ProductValueType = ProductType[keyof ProductType];

export const RefinementType = {
  'ADD': 'add',
  'REMOVE': 'remove',
  'REPLACE': 'replace',
} as const;
export type RefinementTypeType = typeof RefinementType;
export type RefinementTypeValueType = RefinementTypeType[keyof RefinementTypeType];

export const Platform = {
  'OWA': 'owa',
  'AILA_BETA': 'aila-beta',
} as const;
export type PlatformType = typeof Platform;
export type PlatformValueType = PlatformType[keyof PlatformType];

export const EngagementIntent = {
  'EXPLORE': 'explore',
  'REFINE': 'refine',
  'USE': 'use',
  'ADVOCATE': 'advocate',
} as const;
export type EngagementIntentType = typeof EngagementIntent;
export type EngagementIntentValueType = EngagementIntentType[keyof EngagementIntentType];

let PostHogEU: any;
let PosthogAiBeta: any;

export function initAvo(options: {env: AvoEnv;
    webDebugger?: boolean;
    webDebuggerOptions?: webDebuggerOptions;
    strict?: boolean;
    noop?: boolean;
    reportFailureAs?: 'error' | 'warn' | 'log';
    inspector?: AvoInspector;
    avoLogger?: AvoLogger},
    destinationOptions: any,
    PostHogEUDestination: CustomDestination,
    PosthogAiBetaDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }
  
  destinationOptions = destinationOptions || {};
  
  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();
    
  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }
    
    PostHogEU = PostHogEUDestination;
    if (__AVO_ENV__ === 'prod') {
      PostHogEU && PostHogEU.make && PostHogEU.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      PostHogEU && PostHogEU.make && PostHogEU.make(__AVO_ENV__, null);
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for PostHogEU. Head to destination settings in Avo to set a staging key.");
      PostHogEU && PostHogEU.make && PostHogEU.make(__AVO_ENV__, null);
    }
    
    PosthogAiBeta = PosthogAiBetaDestination;
    if (__AVO_ENV__ === 'prod') {
      PosthogAiBeta && PosthogAiBeta.make && PosthogAiBeta.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      PosthogAiBeta && PosthogAiBeta.make && PosthogAiBeta.make(__AVO_ENV__, null);
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Posthog ai-beta. Head to destination settings in Avo to set a staging key.");
      PosthogAiBeta && PosthogAiBeta.make && PosthogAiBeta.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

function assertRefinements(refinements: Refinements[],
    _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertList("w_NhZWeny", "Refinements", refinements));
  if (refinements !== null && refinements !== undefined && refinements.forEach) {
    refinements.forEach(function (value, index) {
      messages = messages.concat(AvoAssert.assertObject("w_NhZWeny", "item at index " + index + " in Refinements", value));
    });
  }
  return messages;
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface LessonAssistantAccessedProperties {
  isLoggedIn: boolean;
  componentType: ComponentTypeValueType;
  product: ProductValueType;
}
/**
 * Lesson Assistant Accessed: A user accesses the AI Lesson Assistant by clicking on the ‘Create a lesson’ button.
 * 
 * When to trigger this event:
 * 1. User clicked on the 'primary' 'Create a lesson' button
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/xsOZT-77ro/trigger/Dz17HUwt4
 * 2. User selects the lesson assistant from the hamburger menu
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/xsOZT-77ro/trigger/FlhqUH53S
 * 3. User selects the 'secondary' 'Create a lesson' button/link lower down the labs homepage
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/xsOZT-77ro/trigger/zF4LQ3vWd
 * 4. The user selects the Lesson Planner from the footer menu on the labs homepage
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/xsOZT-77ro/trigger/DLeioZeJ8
 * 5. User clicks on the 'Get started' button from the search results
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/xsOZT-77ro/trigger/LCMU0NPnivcZrJQ58uvzN
 * 
 * @param properties the properties associatied with this event
 * @param properties.isLoggedIn: Flags whether the user logged in or not before accessing the Oak object 
 * @param properties.componentType: The web component used to carry out the action on the Oak object
 * @param properties.product: Product that the event was sent from to clear distinguish between Oak products
 * 
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/xsOZT-77ro}
 */
export function lessonAssistantAccessed(properties: LessonAssistantAccessedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "p56ECmuX6", name: "Is Logged In", value: properties.isLoggedIn});
  eventPropertiesArray.push({id: "9b_lf1oq8", name: "Component Type", value: properties.componentType});
  eventPropertiesArray.push({id: "JmUs_uxup", name: "Product", value: properties.product});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "xsOZT-77ro", "5b943d555ed4ef26d94efc4397765a3259fa84210bf5c358cdbd6bb34ead5daa", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Assistant Accessed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("xsOZT-77ro", "Lesson Assistant Accessed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Assistant Accessed", eventProperties, "xsOZT-77ro", "5b943d555ed4ef26d94efc4397765a3259fa84210bf5c358cdbd6bb34ead5daa");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Assistant Accessed", (Object as any).assign({}, eventProperties));
    
    // destination PosthogAiBeta
    PosthogAiBeta.logEvent("Lesson Assistant Accessed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonPlanInitiatedProperties {
  text: string;
  componentType: ComponentTypeValueType;
  moderatedContentType: ModeratedContentTypeValueType[] | null | undefined;
  subjectSlug: string | null | undefined;
  keyStageSlug: string | null | undefined;
  lessonPlanTitle: string | null | undefined;
  product: ProductValueType;
  chatId: string;
}
/**
 * Lesson Plan Initiated: A user begins a new lesson plan via the AI Lesson Assistant by typing a text input on the first page of the Lesson Assistant and clicking 'Send Message', selects an example lesson to get started, or begins a new lesson
 * 
 * When to trigger this event:
 * 1. 
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6LvrtHjncf/trigger/1MZ802Uxn
 * 2. 
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6LvrtHjncf/trigger/xgthFuV7v
 * 
 * @param properties the properties associatied with this event
 * @param properties.text: Text captured during the event action e.g. when a user types into a text box and hits enter
 * @param properties.componentType: The web component used to carry out the action on the Oak object
 * @param properties.moderatedContentType: The flag displayed to the user if the content they are looking at is sensitive/blocked
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.lessonPlanTitle: Title of the lesson plan
 * @param properties.product: Product that the event was sent from to clear distinguish between Oak products
 * @param properties.chatId: Distinct ID of the Lesson Plan Chat
 * 
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/6LvrtHjncf}
 */
export function lessonPlanInitiated(properties: LessonPlanInitiatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Qj47EL-lM", name: "Text", value: properties.text});
  eventPropertiesArray.push({id: "9b_lf1oq8", name: "Component Type", value: properties.componentType});
  properties.moderatedContentType !== undefined && properties.moderatedContentType !== null ?
    eventPropertiesArray.push({id: "0NVQREqVg", name: "Moderated Content Type", value: properties.moderatedContentType}) :
    eventPropertiesArray.push({id: "0NVQREqVg", name: "Moderated Content Type", value: null});
  properties.subjectSlug !== undefined && properties.subjectSlug !== null ?
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug}) :
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: null});
  properties.keyStageSlug !== undefined && properties.keyStageSlug !== null ?
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug}) :
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: null});
  properties.lessonPlanTitle !== undefined && properties.lessonPlanTitle !== null ?
    eventPropertiesArray.push({id: "t0gm_zGXX", name: "Lesson Plan Title", value: properties.lessonPlanTitle}) :
    eventPropertiesArray.push({id: "t0gm_zGXX", name: "Lesson Plan Title", value: null});
  eventPropertiesArray.push({id: "JmUs_uxup", name: "Product", value: properties.product});
  eventPropertiesArray.push({id: "CdWOPZ-mn", name: "Chat ID", value: properties.chatId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6LvrtHjncf", "9932ceb4ab57edd216b3313c493004c69b0d398bda1cc712c6f5db5437eaf140", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Plan Initiated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6LvrtHjncf", "Lesson Plan Initiated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Plan Initiated", eventProperties, "6LvrtHjncf", "9932ceb4ab57edd216b3313c493004c69b0d398bda1cc712c6f5db5437eaf140");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Plan Initiated", (Object as any).assign({}, eventProperties));
    
    // destination PosthogAiBeta
    PosthogAiBeta.logEvent("Lesson Plan Initiated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonPlanCompletedProperties {
  subjectSlug: string | null | undefined;
  keyStageSlug: string | null | undefined;
  lessonPlanTitle: string | null | undefined;
  moderatedContentType: ModeratedContentTypeValueType[] | null | undefined;
  product: ProductValueType;
  chatId: string;
}
/**
 * Lesson Plan Completed: A user has reached the end of the lesson plan creation and all lesson resources have become available for download/sharing
 * 
 * @param properties the properties associatied with this event
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.lessonPlanTitle: Title of the lesson plan
 * @param properties.moderatedContentType: The flag displayed to the user if the content they are looking at is sensitive/blocked
 * @param properties.product: Product that the event was sent from to clear distinguish between Oak products
 * @param properties.chatId: Distinct ID of the Lesson Plan Chat
 * 
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/bpddOGEuH6}
 */
export function lessonPlanCompleted(properties: LessonPlanCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.subjectSlug !== undefined && properties.subjectSlug !== null ?
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug}) :
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: null});
  properties.keyStageSlug !== undefined && properties.keyStageSlug !== null ?
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug}) :
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: null});
  properties.lessonPlanTitle !== undefined && properties.lessonPlanTitle !== null ?
    eventPropertiesArray.push({id: "t0gm_zGXX", name: "Lesson Plan Title", value: properties.lessonPlanTitle}) :
    eventPropertiesArray.push({id: "t0gm_zGXX", name: "Lesson Plan Title", value: null});
  properties.moderatedContentType !== undefined && properties.moderatedContentType !== null ?
    eventPropertiesArray.push({id: "0NVQREqVg", name: "Moderated Content Type", value: properties.moderatedContentType}) :
    eventPropertiesArray.push({id: "0NVQREqVg", name: "Moderated Content Type", value: null});
  eventPropertiesArray.push({id: "JmUs_uxup", name: "Product", value: properties.product});
  eventPropertiesArray.push({id: "CdWOPZ-mn", name: "Chat ID", value: properties.chatId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bpddOGEuH6", "30610b124fdffc468b1b4c3b768ffbd9b7433aaf730da7c830ab59b0ef7e71b9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Plan Completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bpddOGEuH6", "Lesson Plan Completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Plan Completed", eventProperties, "bpddOGEuH6", "30610b124fdffc468b1b4c3b768ffbd9b7433aaf730da7c830ab59b0ef7e71b9");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Plan Completed", (Object as any).assign({}, eventProperties));
    
    // destination PosthogAiBeta
    PosthogAiBeta.logEvent("Lesson Plan Completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonPlanResourcesDownloadedProperties {
  subjectSlug: string | null | undefined;
  keyStageSlug: string | null | undefined;
  lessonPlanTitle: string | null | undefined;
  product: ProductValueType;
  resourceType: ResourceTypeValueType[];
  resourceFileType: ResourceFileTypeValueType;
  chatId: string;
}
/**
 * Lesson Plan Resources Downloaded: A user clicks on one of the lesson resources to download them
 * 
 * When to trigger this event:
 * 1. 
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/3JLctzBEI/trigger/G_q-2ddJD
 * 
 * @param properties the properties associatied with this event
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.lessonPlanTitle: Title of the lesson plan
 * @param properties.product: Product that the event was sent from to clear distinguish between Oak products
 * @param properties.resourceType: The lesson resources a teacher selected for download.
 * @param properties.resourceFileType: The file type of the resource related to the event
 * @param properties.chatId: Distinct ID of the Lesson Plan Chat
 * 
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/3JLctzBEI}
 */
export function lessonPlanResourcesDownloaded(properties: LessonPlanResourcesDownloadedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.subjectSlug !== undefined && properties.subjectSlug !== null ?
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug}) :
    eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: null});
  properties.keyStageSlug !== undefined && properties.keyStageSlug !== null ?
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug}) :
    eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: null});
  properties.lessonPlanTitle !== undefined && properties.lessonPlanTitle !== null ?
    eventPropertiesArray.push({id: "t0gm_zGXX", name: "Lesson Plan Title", value: properties.lessonPlanTitle}) :
    eventPropertiesArray.push({id: "t0gm_zGXX", name: "Lesson Plan Title", value: null});
  eventPropertiesArray.push({id: "JmUs_uxup", name: "Product", value: properties.product});
  eventPropertiesArray.push({id: "H_kc7WuVNP", name: "Resource Type", value: properties.resourceType});
  eventPropertiesArray.push({id: "6_1GeCgXu", name: "Resource File Type", value: properties.resourceFileType});
  eventPropertiesArray.push({id: "CdWOPZ-mn", name: "Chat ID", value: properties.chatId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "3JLctzBEI", "a9e6fe6f09d3be9cdc761c860a806fd2e37164ae1c9b9a95173bcd035fa2bf0d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Plan Resources Downloaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("3JLctzBEI", "Lesson Plan Resources Downloaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Plan Resources Downloaded", eventProperties, "3JLctzBEI", "a9e6fe6f09d3be9cdc761c860a806fd2e37164ae1c9b9a95173bcd035fa2bf0d");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Plan Resources Downloaded", (Object as any).assign({}, eventProperties));
    
    // destination PosthogAiBeta
    PosthogAiBeta.logEvent("Lesson Plan Resources Downloaded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonPlanSharedProperties {
  componentType: ComponentTypeValueType;
  subjectSlug: string;
  lessonPlanTitle: string;
  keyStageSlug: string;
  product: ProductValueType;
  chatId: string;
}
/**
 * Lesson Plan Shared: A user shares a lesson plan
 * 
 * When to trigger this event:
 * 1. 
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/_SfSF_WDX/trigger/MtMTyJ4g7
 * 
 * @param properties the properties associatied with this event
 * @param properties.componentType: The web component used to carry out the action on the Oak object
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.lessonPlanTitle: Title of the lesson plan
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.product: Product that the event was sent from to clear distinguish between Oak products
 * @param properties.chatId: Distinct ID of the Lesson Plan Chat
 * 
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/_SfSF_WDX}
 */
export function lessonPlanShared(properties: LessonPlanSharedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "9b_lf1oq8", name: "Component Type", value: properties.componentType});
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "t0gm_zGXX", name: "Lesson Plan Title", value: properties.lessonPlanTitle});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "JmUs_uxup", name: "Product", value: properties.product});
  eventPropertiesArray.push({id: "CdWOPZ-mn", name: "Chat ID", value: properties.chatId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "_SfSF_WDX", "30b578e2cc340e9a8a7c47393d8764fe08192e209037b19e522c48cfa77a3284", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Plan Shared", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("_SfSF_WDX", "Lesson Plan Shared", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Plan Shared", eventProperties, "_SfSF_WDX", "30b578e2cc340e9a8a7c47393d8764fe08192e209037b19e522c48cfa77a3284");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Plan Shared", (Object as any).assign({}, eventProperties));
    
    // destination PosthogAiBeta
    PosthogAiBeta.logEvent("Lesson Plan Shared", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonPlanRefinedProperties {
  subjectSlug: string;
  keyStageSlug: string;
  lessonPlanTitle: string;
  product: ProductValueType;
  componentType: ComponentTypeValueType;
  text: string | null | undefined;
  moderatedContentType: ModeratedContentTypeValueType[] | null | undefined;
  refinements: Refinements[];
  chatId: string;
}
/**
 * Lesson Plan Refined: The user alters or sways the Lesson Assistant's Lesson Plan output in some way
 * 
 * When to trigger this event:
 * 1. User clicks 'regenerate response'
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/KbUf1Z4y1/trigger/rsVGVBp2i
 * 2. User types a selection from a list of suggested Oak lessons
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/KbUf1Z4y1/trigger/0IIeoDG3x
 * 3. A user types an edit in the text box and clicks 'send message'
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/KbUf1Z4y1/trigger/PihT6ErP1
 * 4. The user selects one of the radial options and then clicks on 'Modify section'
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/KbUf1Z4y1/trigger/ElTiEFofSw3JMlJMz6I69
 * 
 * @param properties the properties associatied with this event
 * @param properties.subjectSlug: Human-readable unique ID of the current subject.
 * @param properties.keyStageSlug: Human-readable unique ID of the current key stage.
 * @param properties.lessonPlanTitle: Title of the lesson plan
 * @param properties.product: Product that the event was sent from to clear distinguish between Oak products
 * @param properties.componentType: The web component used to carry out the action on the Oak object
 * @param properties.text: Text captured during the event action e.g. when a user types into a text box and hits enter
 * @param properties.moderatedContentType: The flag displayed to the user if the content they are looking at is sensitive/blocked
 * @param properties.refinements: An array that outlines the refinements made on an object and what part of the object has been impacted by the refinement
 * @param properties.chatId: Distinct ID of the Lesson Plan Chat
 * 
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/KbUf1Z4y1}
 */
export function lessonPlanRefined(properties: LessonPlanRefinedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "8GyPDAapC-", name: "Subject Slug", value: properties.subjectSlug});
  eventPropertiesArray.push({id: "XMx9WMqh0H", name: "Key Stage Slug", value: properties.keyStageSlug});
  eventPropertiesArray.push({id: "t0gm_zGXX", name: "Lesson Plan Title", value: properties.lessonPlanTitle});
  eventPropertiesArray.push({id: "JmUs_uxup", name: "Product", value: properties.product});
  eventPropertiesArray.push({id: "9b_lf1oq8", name: "Component Type", value: properties.componentType});
  properties.text !== undefined && properties.text !== null ?
    eventPropertiesArray.push({id: "Qj47EL-lM", name: "Text", value: properties.text}) :
    eventPropertiesArray.push({id: "Qj47EL-lM", name: "Text", value: null});
  properties.moderatedContentType !== undefined && properties.moderatedContentType !== null ?
    eventPropertiesArray.push({id: "0NVQREqVg", name: "Moderated Content Type", value: properties.moderatedContentType}) :
    eventPropertiesArray.push({id: "0NVQREqVg", name: "Moderated Content Type", value: null});
  eventPropertiesArray.push({id: "w_NhZWeny", name: "Refinements", value: properties.refinements.map(function(value) { return {
      "Refinement Path": value.refinementPath,
      "Refinement Type": value.refinementType,
    }
  })});
  eventPropertiesArray.push({id: "CdWOPZ-mn", name: "Chat ID", value: properties.chatId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertRefinements(properties.refinements));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "KbUf1Z4y1", "cf1758c557643d52553c3081c54df4c258a57db48b9ab235f62e9f8c1c8ca3d9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Plan Refined", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("KbUf1Z4y1", "Lesson Plan Refined", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'Lesson Plan Refined': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Plan Refined", eventProperties, "KbUf1Z4y1", "cf1758c557643d52553c3081c54df4c258a57db48b9ab235f62e9f8c1c8ca3d9");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Plan Refined", (Object as any).assign({}, eventProperties));
    
    // destination PosthogAiBeta
    PosthogAiBeta.logEvent("Lesson Plan Refined", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface LessonPlanTerminatedProperties {
  isUserBlocked: boolean;
  isToxicContent: boolean;
  isThreatDetected: boolean;
  chatId: string;
}
/**
 * Lesson Plan Terminated: The generation of a lesson plan comes to an end due to the user being blocked, reaching the API limit, or the lesson being 'red flagged' and should not be proceeded with. 
 * 
 * @param properties the properties associatied with this event
 * @param properties.isUserBlocked: no description
 * @param properties.isToxicContent: no description
 * @param properties.isThreatDetected: no description
 * @param properties.chatId: Distinct ID of the Lesson Plan Chat
 * 
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/v4cCB-DHB}
 */
export function lessonPlanTerminated(properties: LessonPlanTerminatedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "7W4aqahJq", name: "Is User Blocked", value: properties.isUserBlocked});
  eventPropertiesArray.push({id: "DjR2n3j5u", name: "Is Toxic Content", value: properties.isToxicContent});
  eventPropertiesArray.push({id: "GtZ6dCZGc", name: "Is Threat Detected", value: properties.isThreatDetected});
  eventPropertiesArray.push({id: "CdWOPZ-mn", name: "Chat ID", value: properties.chatId});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "v4cCB-DHB", "c729a5e69f119e4c9bf75cb6b2816a4165352c6658c8732154f5b61fbab280f1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Lesson Plan Terminated", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("v4cCB-DHB", "Lesson Plan Terminated", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Lesson Plan Terminated", eventProperties, "v4cCB-DHB", "c729a5e69f119e4c9bf75cb6b2816a4165352c6658c8732154f5b61fbab280f1");
    }
    // destination PostHogEU
    PostHogEU.logEvent("Lesson Plan Terminated", (Object as any).assign({}, eventProperties));
    
    // destination PosthogAiBeta
    PosthogAiBeta.logEvent("Lesson Plan Terminated", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface UserSignMinusUpCompletedProperties {
  platform: PlatformValueType;
  product: ProductValueType;
  engagementIntent: EngagementIntentValueType;
  componentType: ComponentTypeValueType;
  eventVersion: EventVersionValueType;
  analyticsUseCase: AnalyticsUseCaseValueType | null | undefined;
  userId_: string;
  singleSignOnService: SingleSignOnServiceValueType;
}
/**
 * User Sign-Up Completed: A user creates a clerk account using SSO / email. At this point they are considerd to be "signed-up" and will go into the "onboarding" journey.

Platform: [owa, aila-beta]
Product: user account management
Engagement Intent: explore
Component Type: [see property description]
Event Version: 2.0.0
Analytics Use Case: N/A
 * 
 * When to trigger this event:
 * 1. A user uses SSO or email/password combination to create an account in Clerk.
 * View in Avo: https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/fOlHJypRwg/trigger/aV1XekNBLU
 * 
 * @param properties the properties associatied with this event
 * @param properties.platform: Describes the 'platform' or 'codebase' from which the event was sent. Historically this would have been acorn, but now this will cover OWA and Aila. These should typically also have a one to one relationship with the 'sources' as defined in this Avo project (Oak's Tracking Plan).
 * @param properties.product: Product that the event was sent from to clear distinguish between Oak products
 * @param properties.engagementIntent: The level or intent of engagement behind the event. This is a high-level categorisation that helps determine whether this event is one that represents 'use' or 'advocacy for one of Oak's products, or whether this action would be considered to be related to 'exploring' Oak's products, or some kind of 'refinement' which limits the amount of content displayed (such as in a filter or a browse journey.

This property should be populated with a single value for each event/product combination (i.e. the instance of each event within a product should determine the level of engagement).
 * @param properties.componentType: The web component used to carry out the action on the Oak object
 * @param properties.eventVersion: The version (semver) of the event, which acts as a tag for when the event was introduced/updated. Helps with handling events that could cause downstream logic to change or create 'breaking ' changes in the downstream pipelines.
 * @param properties.analyticsUseCase: User is engaging with the site as a pupil or a teacher as defined by the page url (eg. thenational.academy/pupils or thenational.academy/teachers

NB - This will be removed, but keeping to ease transition from AUC to 'product'
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.singleSignOnService: The Single Sign-On (SSO) Service used at a given stage of sign-up / sign-on.
 * 
 * @see {@link https://www.avo.app/schemas/5PhajbVijwhXVKIJtGMT/events/fOlHJypRwg}
 */
export function userSignUpCompleted(properties: UserSignMinusUpCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "M1ukA4HClh", name: "Platform", value: properties.platform});
  eventPropertiesArray.push({id: "JmUs_uxup", name: "Product", value: properties.product});
  eventPropertiesArray.push({id: "xJlB159-KB", name: "Engagement Intent", value: properties.engagementIntent});
  eventPropertiesArray.push({id: "9b_lf1oq8", name: "Component Type", value: properties.componentType});
  eventPropertiesArray.push({id: "3ZqdV-PbJL", name: "Event Version", value: properties.eventVersion});
  properties.analyticsUseCase !== undefined && properties.analyticsUseCase !== null ?
    eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: properties.analyticsUseCase}) :
    eventPropertiesArray.push({id: "DAS5R4dcvH", name: "Analytics Use Case", value: null});
  eventPropertiesArray.push({id: "03PfePESj", name: "Single Sign-on Service", value: properties.singleSignOnService});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "fOlHJypRwg", "242742c65b591f2b54ce521470730d90001a64ae5429a851cffcb5eb0ef480d8", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("User Sign-Up Completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("fOlHJypRwg", "User Sign-Up Completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("User Sign-Up Completed", eventProperties, "fOlHJypRwg", "242742c65b591f2b54ce521470730d90001a64ae5429a851cffcb5eb0ef480d8");
    }
    // destination PostHogEU
    PostHogEU.identify(properties.userId_);
    PostHogEU.logEvent("User Sign-Up Completed", (Object as any).assign({}, eventProperties));
    
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  avoInspectorApiKey,
  SingleSignOnService,
  ModeratedContentType,
  EventVersion,
  ResourceFileType,
  ComponentType,
  AnalyticsUseCase,
  ResourceType,
  Product,
  RefinementType,
  Platform,
  EngagementIntent,
  lessonAssistantAccessed,
  lessonPlanInitiated,
  lessonPlanCompleted,
  lessonPlanResourcesDownloaded,
  lessonPlanShared,
  lessonPlanRefined,
  lessonPlanTerminated,
  userSignUpCompleted,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["lessonAssistantAccessed","lessonPlanInitiated","lessonPlanCompleted","lessonPlanResourcesDownloaded","lessonPlanShared","lessonPlanRefined","lessonPlanTerminated","userSignUpCompleted"]
